import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import YouTube from "react-youtube";
import Image from "../ImageQuerys/PosterImages";
import PrimaryCta from "../Buttons/primary-cta";
import circles from "../../images/svg/bg-circles1.svg";
import VideoSectionStyles from "./VideoSectionStyles";

const { Title } = Typography;

const VideoSection = (props) => {
  const { sectionData } = props;
  const {
    title,
    videoUrlId,
    videoBtn,
    posterImg,
    posterWrapperClass,
    punctuation,
  } = sectionData;
  const [playing, setPlaying] = useState(false);

  const startPlaying = () => {
    setPlaying(true);
  };
  const endPlaying = () => {
    setPlaying(false);
  };
  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  return (
    <section
      className="video-section"
      style={{
        background: `url('${circles}')`,
      }}
    >
      <Title level={2} className="text-center">
        {title}
        <span className="blue_dot">{punctuation || "."}</span>
      </Title>
      <div className="video-section__player">
        {!playing ? (
          <>
            <Image
              imageName={posterImg}
              className={`video-section__poster ${posterWrapperClass}`}
            />
            <PrimaryCta
              className="play-video"
              isVideo
              ctaTitle={videoBtn}
              onClick={startPlaying}
            />
          </>
        ) : (
          <YouTube
            videoId={videoUrlId}
            onEnd={endPlaying}
            onReady={onPlayerReady}
            className="video-section__yt"
            opts={{
              playerVars: {
                autoplay: 1,
              },
              height: "100%",
              width: "100%",
            }}
          />
        )}
      </div>

      <VideoSectionStyles />
    </section>
  );
};

VideoSection.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
VideoSection.defaultProps = {
  sectionData: "",
};

export default VideoSection;
